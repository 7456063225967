<template>
    <div>
        <div class="d-flex flex-column flex-md-row align-md-center mb-3">
            <label for="available_codes_field" class="mr-0 mr-md-4 primary--text">{{ $t('forms.PrizeForm.available_codes.label') }}</label>
            <FileInput 
                ref="available_codes_field"
                type="update-prize-available-codes"
                :params="{ prize_id: value.id }"
                :maxSizeMB="1"
                class="mt-2 mt-md-0"
                accept=".csv"
                v-on="listeners"
                v-bind="attrs"
                @input="onInput"
            />
        </div>
        <div>
            <p v-show="showImportHint" class="secondary--text">
                <i18n path="forms.PrizeForm.available_codes.import_hint">
                    <template #link>
                        <router-link :to="{ name: 'import.list' }">{{ $t('actions.click_here') }}</router-link>
                    </template>
                </i18n>
            </p>

            <p class="secondary--text">{{ $t('forms.PrizeForm.available_codes.hint') }}</p>

            <v-btn outlined height="36px" width="200px" class="primary--text"
                href="/import_prize_available_codes.csv" download
            >{{ $t('forms.PrizeForm.available_codes.download_template') }}</v-btn>
        </div>
    </div>
</template>
<script>
import Prize from '@/models/Prize'
import FileInput from '@/components/inputs/FileInput'

export default {
    name: 'PrizeFormAvailableCodesInput',
    components: {
        FileInput,
    },
    props: {
        value: Prize,
        parent: Object,
    },
    data: vm => ({
        showImportHint: false,
    }),
    methods: {
        onInput(v) {
            this.showImportHint = true
            this.$emit('input', v)
        },
        async requestFileUpload() {
            await this.$refs.available_codes_field.requestFileUpload()
        },
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
}
</script>