var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex flex-column flex-md-row align-md-center mb-3" },
      [
        _c(
          "label",
          {
            staticClass: "mr-0 mr-md-4 primary--text",
            attrs: { for: "available_codes_field" }
          },
          [_vm._v(_vm._s(_vm.$t("forms.PrizeForm.available_codes.label")))]
        ),
        _c(
          "FileInput",
          _vm._g(
            _vm._b(
              {
                ref: "available_codes_field",
                staticClass: "mt-2 mt-md-0",
                attrs: {
                  type: "update-prize-available-codes",
                  params: { prize_id: _vm.value.id },
                  maxSizeMB: 1,
                  accept: ".csv"
                },
                on: { input: _vm.onInput }
              },
              "FileInput",
              _vm.attrs,
              false
            ),
            _vm.listeners
          )
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showImportHint,
                expression: "showImportHint"
              }
            ],
            staticClass: "secondary--text"
          },
          [
            _c("i18n", {
              attrs: { path: "forms.PrizeForm.available_codes.import_hint" },
              scopedSlots: _vm._u([
                {
                  key: "link",
                  fn: function() {
                    return [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "import.list" } } },
                        [_vm._v(_vm._s(_vm.$t("actions.click_here")))]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        ),
        _c("p", { staticClass: "secondary--text" }, [
          _vm._v(_vm._s(_vm.$t("forms.PrizeForm.available_codes.hint")))
        ]),
        _c(
          "v-btn",
          {
            staticClass: "primary--text",
            attrs: {
              outlined: "",
              height: "36px",
              width: "200px",
              href: "/import_prize_available_codes.csv",
              download: ""
            }
          },
          [
            _vm._v(
              _vm._s(
                _vm.$t("forms.PrizeForm.available_codes.download_template")
              )
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }