var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0", attrs: { fluid: "" } },
    [
      _c("CreateModal", {
        ref: "create_modal",
        attrs: { prize: _vm.selectedPrize }
      }),
      _c("EditModal", {
        ref: "edit_modal",
        attrs: { prize: _vm.selectedPrize }
      }),
      _c(
        "v-row",
        { staticClass: "px-0" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center py-0", attrs: { cols: "12" } },
            [
              _c("span", { staticClass: "pl-5 pl-md-12 section-title" }, [
                _vm._v(_vm._s(_vm.$t("views.prizes.title")))
              ]),
              _c("v-spacer")
            ],
            1
          ),
          _vm.$vuetify.breakpoint.xs
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0 pl-4 pr-1", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "can",
                                  rawName: "v-can:list",
                                  value: "PrizeCategory",
                                  expression: "'PrizeCategory'",
                                  arg: "list"
                                }
                              ],
                              staticClass: "success--text",
                              attrs: {
                                outlined: "",
                                height: "36px",
                                width: "100%",
                                to: { name: "prizeCategory.list" }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("views.prizes.category")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pb-0 pr-4 pl-1", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary--text",
                              attrs: {
                                outlined: "",
                                height: "36px",
                                width: "100%"
                              },
                              on: { click: _vm.openCreate }
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.add")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "py-0 px-5 px-md-12", attrs: { cols: "12" } },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "px-sm-4 mb-2",
                  attrs: {
                    height: "40px",
                    color: "primary",
                    "slider-color": "primary",
                    "background-color": "background",
                    "show-arrows": ""
                  },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _vm._l(_vm.tabs, function(item, index) {
                    return _c(
                      "v-tab",
                      {
                        key: index,
                        staticClass: "tab-title",
                        attrs: {
                          disabled: item.disabled,
                          "active-class": "tab-title-active"
                        }
                      },
                      [_vm._v(" " + _vm._s(item.title) + " ")]
                    )
                  }),
                  _c("v-spacer"),
                  !_vm.$vuetify.breakpoint.xs
                    ? _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "can",
                                  rawName: "v-can:list",
                                  value: "PrizeCategory",
                                  expression: "'PrizeCategory'",
                                  arg: "list"
                                }
                              ],
                              staticClass: "mr-3 success--text",
                              attrs: {
                                outlined: "",
                                height: "36px",
                                width: "100px",
                                to: { name: "prizeCategory.list" }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("views.prizes.category")))]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary--text",
                              attrs: {
                                outlined: "",
                                height: "36px",
                                width: "100px"
                              },
                              on: { click: _vm.openCreate }
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.add")))]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                _vm._l(_vm.tabs, function(item, index) {
                  return _c(
                    "v-tab-item",
                    { key: index, staticClass: "pa-4" },
                    [
                      _c("PrizeIterator", {
                        ref: "prize_iterator",
                        refInFor: true,
                        attrs: {
                          fetchFunction: _vm.fetchPrizes,
                          colProps: { cols: 12, sm: 4, md: 3, lg: 2 },
                          perPage: _vm.perPage
                        },
                        on: { error: _vm.errorHandler },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "card-absolute",
                              fn: function(ref) {
                                var prize = ref.prize
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-1 mr-1 background",
                                          attrs: {
                                            icon: "",
                                            width: "30px",
                                            height: "30px"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openEdit(prize)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-small": "" } },
                                            [_vm._v("$cp_edit")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-1 mr-1 background",
                                          attrs: {
                                            icon: "",
                                            width: "30px",
                                            height: "30px"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deletePrize(prize)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-small": "" } },
                                            [_vm._v("$cp_trash")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }