<template>
    <v-container fluid class="pt-0">
        <CreateModal ref="create_modal" :prize="selectedPrize" />
        <EditModal ref="edit_modal" :prize="selectedPrize" />

        <v-row class="px-0">
            <v-col cols="12" class="d-flex align-center py-0">
                <span class="pl-5 pl-md-12 section-title">{{ $t('views.prizes.title') }}</span>
                <v-spacer></v-spacer>
            </v-col>

            <!-- Versão mobile -->
            <v-col v-if="$vuetify.breakpoint.xs" cols="12">
                <v-row>
                    <v-col cols="6" class="pb-0 pl-4 pr-1">
                        <v-btn v-can:list="'PrizeCategory'" outlined height="36px" width="100%" class="success--text" :to="{ name: 'prizeCategory.list' }">{{ $t('views.prizes.category')}}</v-btn>
                    </v-col>

                    <v-col cols="6" class="pb-0 pr-4 pl-1">
                        <v-btn outlined height="36px" width="100%" class="primary--text" @click="openCreate">{{ $t('actions.add') }}</v-btn>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" class="py-0 px-5 px-md-12">
                <v-tabs
                    v-model="tab"
                    height="40px"
                    color="primary"
                    slider-color="primary"
                    background-color="background"
                    class="px-sm-4 mb-2"
                    show-arrows
                >
                    <v-tab
                        v-for="(item, index) of tabs"
                        :key="index"
                        :disabled="item.disabled"
                        class="tab-title"
                        active-class="tab-title-active"
                    >
                        {{ item.title }}
                    </v-tab>
                    <v-spacer></v-spacer>
                    <!-- Versão desktop -->
                    <div v-if="!$vuetify.breakpoint.xs" class="d-flex">
                        <v-btn v-can:list="'PrizeCategory'" outlined height="36px" width="100px" class="mr-3 success--text" :to="{ name: 'prizeCategory.list' }">{{ $t('views.prizes.category')}}</v-btn>
                        <v-btn outlined height="36px" width="100px" class="primary--text" @click="openCreate">{{ $t('actions.add') }}</v-btn>
                    </div>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item
                        v-for="(item, index) of tabs"
                        :key="index"
                        class="pa-4"
                    >
                        <PrizeIterator
                            ref="prize_iterator"
                            :fetchFunction="fetchPrizes"
                            @error="errorHandler"
                            :colProps="{ cols: 12, sm: 4, md: 3, lg: 2 }"
                            :perPage="perPage"
                        >
                            <template #card-absolute="{ prize }">
                                <div class="d-flex">
                                    <v-btn icon width="30px" height="30px" class="mt-1 mr-1 background" @click="openEdit(prize)">
                                        <v-icon x-small>$cp_edit</v-icon>
                                    </v-btn>
                                    <v-btn icon width="30px" height="30px" class="mt-1 mr-1 background" @click="deletePrize(prize)">
                                        <v-icon x-small>$cp_trash</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </PrizeIterator>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import PrizeIterator        from '@/shared/components/iterators/PrizeIterator'
import CreateModal          from '@/components/modals/prizes/CreateModal'
import EditModal            from '@/components/modals/prizes/EditModal'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Prize                from '@/models/Prize'

export default {
    name: 'PrizesList',
    components: { CreateModal, EditModal, PrizeIterator },
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        tab: null,
        selectedPrize: new Prize(),

        hasDeleteError: false,
        loading: false,
    }),
    methods: {
        async openCreate() {
            this.selectedPrize = new Prize()
            let r = await this.$refs.create_modal.open()

            if (r) {
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t('views.prizes.Create.alerts.success'), 'success', resolve)
                })
                await this.preFetchItems()
            }
        },
        async openEdit(item) {
            // Precisa criar uma nova instância
            this.selectedPrize = new Prize(item)
            let r = await this.$refs.edit_modal.open()

            if (r) {
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t('views.prizes.Edit.alerts.success'), 'success', resolve)
                })
                await this.preFetchItems()
            }
        },
        async preFetchItems() {
            if (this.$refs.prize_iterator[this.tab])
                await this.$refs.prize_iterator[this.tab].preFetchItems()
        },
        async fetchPrizes(pagination = {}) {
            this.loading = true
            let res = await Prize.list({ params: pagination })
                .catch(this.errorHandler)
            this.loading = false

            return res || []
        },
        async deletePrize(prize) {
            this.hasDeleteError = false

            await prize.delete()
                .catch(this.preErrorHandler)

            if (this.hasDeleteError)
                return

            this.$emit('deleted')
            this.$bus.$emit('message', this.$t('views.prizes.remove_success'), 'success')
            await this.preFetchItems()
        },
        preErrorHandler(e) {
            this.loading        = false
            this.hasDeleteError = true

            let status = this.$lodash.get(e, 'response.status')

            if (status == 404) {
                this.$bus.$emit('message', this.$t('views.prizes.not_found'), 'info')
                return
            }

            this.errorHandler(e)
        },
    },
    computed: {
        tabs() {
            return [
                { title: this.$t('views.prizes.tabs.all'),     disabled: false },
                { title: this.$t('views.prizes.tabs.removed'), disabled: true  },
            ]
        },
        perPage() {
            return this.$vuetify.breakpoint.lgAndUp ? 12  :
                   this.$vuetify.breakpoint.mdAndUp ? 8  :
                   this.$vuetify.breakpoint.smAndUp ? 6 : 3        
        }
    },
}
</script>

<style lang="scss">
.tab-title {
    font-size: 14px;
    text-transform: none;
}

.tab-title-active {
    font-weight: bold;
}

.v-tabs-items {
    border-radius: 8px;
}
</style>