<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="triggerSubmit"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        :max-width="$vuetify.breakpoint.mdAndUp ? '570px' : '330px'"
        persistent
    >
        <PrizeForm
            ref="prize_form"
            v-model="prize"
            :isFormValid.sync="isFormValid"
            :formType="'EDIT'"
            @submit="confirm"
            @triggerSubmit="triggerSubmit"
            v-show="!loading"
            :loading="loading"
        />
        <span v-if="loading">{{ $t('forms.wait') }}</span>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'
import PrizeForm from '@/components/forms/PrizeForm'
import BaseModalMixin from '../BaseModalMixin'
import Prize from '@/models/Prize'

export default {
    name: 'PrizesEditModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal, PrizeForm },
    props: {
        prize: {
            type: Prize,
            required: true,
        },
    },
    data: vm => {
        return {
            isFormValid: false,
            title: vm.$t('views.prizes.Edit.title'),
            loading: false,
        }
    },
    methods: {
        triggerSubmit() {
            this.$refs.prize_form.submit()
        },
        async beforeConfirm(prize) {
            if (!this.isFormValid)
                return false

            this.loading = true

            // Tenta subir os arquivos, se existentes
            let errType
            await this.$refs.prize_form.triggerFileUploads()
                .catch(err => errType = this.errorHandler(err))

            if (!errType) {
                let response = await prize.update()
                    .catch(err => errType = this.errorHandler(err))
            }

            this.loading = false
            
            if (errType) {
                const definedErrors = [
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.prizes.Edit.alerts.${errType}`), 'error', resolve)
                })
                return false
            }

            this.resolveValue = this.prize
            return true
        },
        errorHandler(err) {
            let errType
            if (err.message == 'Network Error')
                return 'network_error'

            if (err.response.data.type)
                return err.response.data.type

            return 'error'
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.save'),
                disabled: !this.isFormValid,
                loading: this.loading,
            }
        },
        computedCancelButton() {
            return {
                disabled: this.loading,
            }
        },
    },
}
</script>