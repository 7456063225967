var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "SelectInput",
        _vm._g(
          _vm._b(
            {
              ref: "input",
              attrs: {
                value: _vm.value,
                items: _vm.categories,
                "item-text": "name",
                "item-value": "id",
                "error-messages": _vm.errorMessage,
                clearable: true,
                "return-object": ""
              },
              on: { input: _vm.input }
            },
            "SelectInput",
            _vm.attrs,
            false
          ),
          _vm.listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }