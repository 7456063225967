var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-col",
                { staticClass: "pt-0 pb-10", attrs: { cols: "12" } },
                [
                  _c("AsidePrizeInput", {
                    ref: "aside_prize_input",
                    attrs: {
                      formType: _vm.formType,
                      previewMaxHeight: "118px",
                      previewMaxWidth: "240px"
                    },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "12", md: "7" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "name_field",
                        attrs: {
                          label: _vm.$t("forms.PrizeForm.name.label"),
                          hint: _vm.$t("forms.PrizeForm.name.hint"),
                          placeholder: _vm.$t(
                            "forms.PrizeForm.name.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getErrorMessages("name")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("name")
                          }
                        },
                        model: {
                          value: _vm.value.name,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "name", $$v)
                          },
                          expression: "value.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("CurrencyInput", {
                        ref: "value_field",
                        attrs: {
                          label: _vm.$t("forms.PrizeForm.value.label"),
                          hint: _vm.$t("forms.PrizeForm.value.hint"),
                          placeholder: _vm.$t(
                            "forms.PrizeForm.value.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getErrorMessages("value"),
                          suffix: _vm.$t("forms.PrizeForm.value.suffix")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("value")
                          }
                        },
                        model: {
                          value: _vm.value.value,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "value", $$v)
                          },
                          expression: "value.value"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("AmountInput", {
                        ref: "expires_in_field",
                        attrs: {
                          label: _vm.$t("forms.PrizeForm.expires_in.label"),
                          hint: _vm.$t("forms.PrizeForm.expires_in.hint"),
                          placeholder: _vm.$t(
                            "forms.PrizeForm.expires_in.placeholder"
                          ),
                          required: "",
                          "error-messages": _vm.getErrorMessages("expires_in"),
                          suffix: _vm.$t("forms.PrizeForm.expires_in.suffix")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("expires_in")
                          }
                        },
                        model: {
                          value: _vm.value.expires_in,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "expires_in", $$v)
                          },
                          expression: "value.expires_in"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", xs: "12" } },
                    [
                      _c("PrizeCategorySelect", {
                        ref: "category_id_select",
                        attrs: {
                          value: _vm.value.category,
                          label: _vm.$t("forms.PrizeForm.category.label"),
                          "error-messages": _vm.getErrorMessages("category"),
                          "item-value": "id",
                          "item-text": "name"
                        },
                        on: {
                          input: function($event) {
                            _vm.onCategoryId($event)
                            _vm.clearErrorMessages("category")
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                {
                  staticClass: "pt-6",
                  attrs: { cols: "12", sm: "12", md: "5" }
                },
                [
                  _c("AsidePrizeInput", {
                    ref: "aside_prize_input",
                    attrs: {
                      formType: _vm.formType,
                      previewMaxHeight: "270px",
                      previewMaxWidth: "200px"
                    },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", xs: "12", sm: "12" } },
            [
              _c("cp-textarea", {
                ref: "description_field",
                attrs: {
                  label: _vm.$t("forms.PrizeForm.description.label"),
                  hint: _vm.$t("forms.PrizeForm.description.hint"),
                  placeholder: _vm.$t(
                    "forms.PrizeForm.description.placeholder"
                  ),
                  required: "",
                  type: "text",
                  "error-messages": _vm.getErrorMessages("description"),
                  rows: "4"
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("description")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("triggerSubmit")
                  }
                },
                model: {
                  value: _vm.value.description,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "description", $$v)
                  },
                  expression: "value.description"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", xs: "12", sm: "12" } },
            [
              _c("cp-text-field", {
                ref: "external_id_field",
                attrs: {
                  label: _vm.$t("forms.PrizeForm.external_id.label"),
                  placeholder: _vm.$t(
                    "forms.PrizeForm.external_id.placeholder"
                  ),
                  hint: _vm.$t("forms.PrizeForm.external_id.hint"),
                  type: "text",
                  disabled: _vm.value.integrator_id,
                  "error-messages": _vm.getErrorMessages("external_id")
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("external_id")
                  }
                },
                model: {
                  value: _vm.value.external_id,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "external_id", $$v)
                  },
                  expression: "value.external_id"
                }
              })
            ],
            1
          ),
          _c("v-col", { staticClass: "pt-8 pb-4", attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column flex-md-row align-md-center" },
              [
                _c(
                  "label",
                  {
                    staticClass: "mr-0 mr-md-4 primary--text",
                    attrs: { for: "tos_url_field" }
                  },
                  [_vm._v(_vm._s(_vm.$t("forms.PrizeForm.tos_url.label")))]
                ),
                _c("FileInput", {
                  ref: "tos_url_field",
                  staticClass: "mt-2 mt-md-0",
                  attrs: {
                    value: _vm.value.tos_url,
                    type: "update-prize-tos",
                    params: { prize_id: _vm.value.id },
                    maxSizeMB: 4,
                    manual: _vm.formType == "CREATE",
                    "error-messages": _vm.getErrorMessages("tos_url")
                  },
                  on: {
                    input: function($event) {
                      _vm.onInput("tos_url", $event)
                      _vm.clearErrorMessages("tos_url")
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "v-col",
            { staticClass: "pt-8 pb-4", attrs: { cols: "12" } },
            [
              _c("PrizeFormAvailableCodesInput", {
                ref: "available_codes_input",
                attrs: {
                  value: _vm.value,
                  "error-messages": _vm.getErrorMessages("available_codes"),
                  manual: _vm.formType == "CREATE"
                },
                on: {
                  input: function($event) {
                    _vm.onInput("available_codes", $event)
                    _vm.clearErrorMessages("available_codes")
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }