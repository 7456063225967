<template>
    <div>
            <!-- :loading="computedLoading" -->
        <SelectInput
            ref="input"
            :value="value"
            @input="input"
            v-bind="attrs"
            v-on="listeners"
            :items="categories"
            item-text="name"
            item-value="id"
            :error-messages="errorMessage"
            :clearable="true"
            return-object
        />
            <!-- return-object -->
            <!-- @click:clear="$nextTick(() => (value = null ))" -->
     </div>
</template>

<script>
import PrizeCategory from '@/models/PrizeCategory'
import SelectInput from '@/shared/components/inputs/SelectInput'

export default {
    name: 'PrizeCategorySelect',
    components: {
        SelectInput,
    },
    props: {
        value: {
            type: [Object, String]
        },
    },
    data: vm =>({
        prizeCategory: new PrizeCategory,
        categories: [],
        errorMessage: '',
    }),
     async created() {
        await this.fetchPrizeCategory()
    },
    methods: {
        async fetchPrizeCategory() {
            
            const response = await PrizeCategory.list()
                .catch(err => {
                    if(err.message == 'Network Error') {
                        this.errorMessage = err.message
                        return
                    }})

            if (!response || !response.data || response.status != 200)
                return        
            
            const hydratedData = []
            response.data.forEach(category => hydratedData.push(new PrizeCategory(category)))

            this.categories = hydratedData
        },
        input(v) {
            this.$emit('input', v)
        },
    },
    computed: {
        //  Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        // Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
        attrs() {
            const { ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
}
</script>
