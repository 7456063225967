<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <!-- 
                Ocorre uma dupliação desssa coluna para atender os requisitos do layout. Seria
                possível fazer uma pequena alteração e utilizar a classe flex-column-reverse para
                obter o mesmo resultado, entretanto essa solução, segundo a documentação Vuetify,
                pode apresentar problemas no IE11 e no Safari. Portanto se optou por essa duplicação
                de código
            -->
            <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="pt-0 pb-10">
                <AsidePrizeInput
                    ref="aside_prize_input"
                    v-model="value"
                    :formType="formType"
                    previewMaxHeight="118px"
                    previewMaxWidth="240px"
                />
            </v-col>

            <v-col cols="12" sm="12" md="7" class="py-0">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            ref="name_field"
                            :label="$t('forms.PrizeForm.name.label')"
                            :hint="$t('forms.PrizeForm.name.hint')"
                            :placeholder="$t('forms.PrizeForm.name.placeholder')"
                            required type="text"
                            :error-messages="getErrorMessages('name')"
                            v-model="value.name"
                            @input="clearErrorMessages('name')"
                        />
                    </v-col>
                    
                    <v-col cols="12" class="py-0">
                        <CurrencyInput
                            ref="value_field"
                            :label="$t('forms.PrizeForm.value.label')"
                            :hint="$t('forms.PrizeForm.value.hint')"
                            :placeholder="$t('forms.PrizeForm.value.placeholder')"
                            required type="text"
                            :error-messages="getErrorMessages('value')"
                            v-model="value.value"
                            @input="clearErrorMessages('value')"
                            :suffix="$t('forms.PrizeForm.value.suffix')"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <AmountInput   
                            ref="expires_in_field"
                            :label="$t('forms.PrizeForm.expires_in.label')"
                            :hint="$t('forms.PrizeForm.expires_in.hint')"
                            :placeholder="$t('forms.PrizeForm.expires_in.placeholder')"
                            required
                            :error-messages="getErrorMessages('expires_in')"
                            v-model="value.expires_in"
                            @input="clearErrorMessages('expires_in')"
                            :suffix="$t('forms.PrizeForm.expires_in.suffix')"
                        />
                    </v-col>
    
                    <v-col cols="12" xs="12">
                        <PrizeCategorySelect
                            ref="category_id_select"
                            :value="value.category"
                            :label="$t('forms.PrizeForm.category.label')"
                            :error-messages="getErrorMessages('category')"
                            item-value="id"
                            item-text="name"
                            @input="onCategoryId($event); clearErrorMessages('category');"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" sm="12" md="5" class="pt-6">
                <AsidePrizeInput
                    ref="aside_prize_input"
                    v-model="value"
                    :formType="formType"
                    previewMaxHeight="270px"
                    previewMaxWidth="200px"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="12" class="py-0">
                <cp-textarea
                    ref="description_field"
                    :label="$t('forms.PrizeForm.description.label')"
                    :hint="$t('forms.PrizeForm.description.hint')"
                    :placeholder="$t('forms.PrizeForm.description.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('description')"
                    v-model="value.description"
                    rows="4"
                    @input="clearErrorMessages('description')"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="12" class="py-0">
                <cp-text-field
                    :ref="`external_id_field`"
                    :label="$t('forms.PrizeForm.external_id.label')"
                    :placeholder="$t('forms.PrizeForm.external_id.placeholder')"
                    :hint="$t('forms.PrizeForm.external_id.hint')"
                    type="text"
                    v-model="value.external_id"
                    :disabled="value.integrator_id"
                    :error-messages="getErrorMessages('external_id')"
                    @input="clearErrorMessages('external_id')"
                />
            </v-col>

            <v-col cols="12" class="pt-8 pb-4">
                <div class="d-flex flex-column flex-md-row align-md-center">
                    <label for="tos_url_field" class="mr-0 mr-md-4 primary--text">{{ $t('forms.PrizeForm.tos_url.label') }}</label>
                    <FileInput 
                        ref="tos_url_field"
                        :value="value.tos_url"
                        type="update-prize-tos"
                        :params="{ prize_id: value.id }"
                        :maxSizeMB="4"
                        :manual="formType == 'CREATE'"
                        :error-messages="getErrorMessages('tos_url')"
                        @input="onInput('tos_url', $event); clearErrorMessages('tos_url')"
                        class="mt-2 mt-md-0"
                    />
                </div>
            </v-col>   

            <v-col cols="12" class="pt-8 pb-4">
                <PrizeFormAvailableCodesInput
                    ref="available_codes_input"
                    :value="value"
                    :error-messages="getErrorMessages('available_codes')"
                    :manual="formType == 'CREATE'"
                    @input="onInput('available_codes', $event); clearErrorMessages('available_codes');"
                />
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import FormMixin                    from '@/mixins/FormMixin'
import Prize                        from '@/models/Prize'
import AmountInput                  from '@/components/inputs/AmountInput'
import CurrencyInput                from '@/components/inputs/CurrencyInput'
import PrizeCategorySelect          from '@/components/inputs/PrizeCategorySelect.vue'
import AsidePrizeInput              from './commons/AsidePrizeInput'
import PrizeFormAvailableCodesInput from './commons/PrizeFormAvailableCodesInput'
import FileInput                    from '@/components/inputs/FileInput'

export default {
    name: 'PrizeForm',
    mixins: [ FormMixin(Prize, 'name_field') ],
    components: {
        AmountInput,
        CurrencyInput,
        PrizeCategorySelect,
        AsidePrizeInput,
        FileInput,
        PrizeFormAvailableCodesInput,
    },
    props: {
        Prize: Object,
        formType: String,
        isFirstPrize: {
            type: Boolean,
            default: false,
        },
    },
    data: vm => ({
        photoPreview: null,
    }),
    methods: {
        presubmit() {
            // Salva o preview da foto
            this.photoPreview = this.value.photo_url
            this.value.photo_url = null
            return true
        },
        postsubmit() {
            // Recupera o preview da foto
            this.value.photo_url = this.photoPreview
            this.photoPreview = null
            return true
        },

        async triggerFileUploads() {
            await this.$refs.aside_prize_input.triggerFileUploads()
            await this.$refs.tos_url_field.requestFileUpload()
            await this.$refs.available_codes_input.requestFileUpload()
        },

        onInput(attributeName, value) {
            this.$set(this.value, attributeName, value)
        },
        onCategoryId(event) {
            if (!event)
                event = null

           this.value.category = event
        },
    },
}
</script>