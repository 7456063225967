var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [
          _c("ImageInput", {
            ref: "photo_field",
            staticClass: "mx-auto",
            attrs: {
              previewMaxHeight: _vm.previewMaxHeight,
              previewMaxWidth: _vm.previewMaxWidth,
              type: "update-prize-photo",
              params: { prize_id: _vm.value.id },
              required: "",
              manual: _vm.formType == "CREATE",
              "error-messages": _vm.getErrorMessages("photo_url")
            },
            on: {
              input: function($event) {
                return _vm.clearErrorMessages("photo_url")
              }
            },
            model: {
              value: _vm.value.photo_url,
              callback: function($$v) {
                _vm.$set(_vm.value, "photo_url", $$v)
              },
              expression: "value.photo_url"
            }
          }),
          _c("v-checkbox", {
            attrs: {
              "error-messages": _vm.getErrorMessages("show_in_store"),
              required: ""
            },
            on: {
              input: function($event) {
                return _vm.clearErrorMessages("show_in_store")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function() {
                  return [
                    _c("span", { staticClass: "checkbox-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("forms.PrizeForm.show_in_store.label"))
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.value.show_in_store,
              callback: function($$v) {
                _vm.$set(_vm.value, "show_in_store", $$v)
              },
              expression: "value.show_in_store"
            }
          }),
          _c("div", { staticClass: "notes" }, [
            _vm._v(" " + _vm._s(_vm.$t("forms.PrizeForm.show_in_store.hint"))),
            _c("br"),
            _c("br")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }