<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <ImageInput
                ref="photo_field"
                :previewMaxHeight="previewMaxHeight"
                :previewMaxWidth="previewMaxWidth"
                v-model="value.photo_url"
                type="update-prize-photo"
                :params="{ prize_id: value.id }"
                required
                :manual="formType == 'CREATE'"
                :error-messages="getErrorMessages('photo_url')"
                @input="clearErrorMessages('photo_url')"
                class="mx-auto"
            />

            <v-checkbox
                v-model="value.show_in_store"
                :error-messages="getErrorMessages('show_in_store')"
                @input="clearErrorMessages('show_in_store')"
                required
            >
                <template #label>
                    <span class="checkbox-label">{{ $t('forms.PrizeForm.show_in_store.label') }}</span>
                </template>
            </v-checkbox>
            
            <div class="notes">
                {{ $t('forms.PrizeForm.show_in_store.hint') }}<br><br>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import ImageInput from '@/components/inputs/ImageInput'
import FormMixin from '@/mixins/FormMixin'
import Prize from '@/models/Prize'

export default {
    name: 'AsidePrizeInput',
    mixins: [ FormMixin(Prize, '') ],
    components: { ImageInput },
    props: {
        formType: {
            type: String,
            required: true,
        },
        previewMaxHeight: {
            type: String,
            default: '270px'
        },
        previewMaxWidth: {
            type: String,
            default: '200px',
        }
    },
    methods: {
        async triggerFileUploads() {
            await this.$refs.photo_field.requestFileUpload()
        },
    },
}
</script>

<style scoped lang="scss">
.notes {
    font-size: 12px;
    line-height: 1;
}

.checkbox-label {
    font-size: 14px;
}
</style>